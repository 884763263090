import { UserType } from "models/User";
import { FormEvent, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { getCookies, login, saveLoggedInUser } from "services/loginService";

interface LoginFormProps {
    showForgotPasswordModal: any;
    userType: UserType;
}

const { remember, user, pwd } = getCookies();

export default ({ showForgotPasswordModal, userType }: LoginFormProps) => {
    const isCamplify =
        useLocation().pathname.trim().toLowerCase() === "/camplify";
    const [email, setEmail] = useState(user ?? "");
    const [password, setPassword] = useState(pwd ?? "");
    const [rememberMe, setRememberMe] = useState(remember === "1");

    const emailRef = useRef<any>(null);
    const [emailError, setEmailError] = useState("");
    const pwdRef = useRef<any>(null);
    const [pwdError, setPwdError] = useState("");

    const [loginError, setLoginError] = useState("");

    const [signingIn, setSigningIn] = useState(false);

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (signingIn) {
            return;
        }

        let errorRef: any;
        if (!email || !email.trim()) {
            setEmailError(
                userType === UserType.Police
                    ? "Enter the Reg Number"
                    : "Enter your email"
            );
            errorRef = emailRef;
        }
        if (!password) {
            setPwdError("Enter your password");
            if (!errorRef) {
                errorRef = pwdRef;
            }
        }
        if (errorRef) {
            errorRef.current?.focus();
            return;
        }

        setSigningIn(true);
        login(email, password, rememberMe, userType).then((response) => {
            setSigningIn(false);
            if (response.message) {
                setLoginError(response.message);
            } else {
                saveLoggedInUser(response);
                window.location.href = response.landingUrl ?? "/tracking";
            }
        });
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group className="mt-4 mb-4">
                <Form.Control
                    type="text"
                    placeholder={
                        userType === UserType.Police
                            ? "Enter the Reg Number"
                            : "Enter your email"
                    }
                    ref={emailRef}
                    maxLength={200}
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value);
                        setEmailError("");
                    }}
                />
                {emailError && (
                    <Form.Text className="text-danger">{emailError}</Form.Text>
                )}
            </Form.Group>
            <Form.Group className="mb-2">
                <Form.Control
                    type="password"
                    placeholder="Enter your password"
                    ref={pwdRef}
                    maxLength={50}
                    value={password}
                    onChange={(e) => {
                        setPassword(e.target.value);
                        setPwdError("");
                    }}
                />
                {pwdError && (
                    <Form.Text className="text-danger">{pwdError}</Form.Text>
                )}
            </Form.Group>
            <Form.Group>
                <div className="inline">
                    <div>
                        <Form.Check
                            id="remember-me"
                            checked={rememberMe}
                            onChange={() => setRememberMe(!rememberMe)}
                        />
                        <Form.Label htmlFor="remember-me">
                            Remember Me
                        </Form.Label>
                    </div>
                    <Button variant="link" onClick={showForgotPasswordModal}>
                        Forgot password
                    </Button>
                </div>
            </Form.Group>
            <Form.Group>
                <div className="text-center">
                    <Button color="primary" type="submit">
                        {signingIn ? "Signing In..." : "Sign In"}
                    </Button>
                    {isCamplify && (
                        <p className="mt-3">
                            After sign in or when activating a new device, you
                            will be directed to our partner Black Knight's
                            system.
                        </p>
                    )}
                </div>
            </Form.Group>
            {loginError && (
                <Form.Group className="text-center mt-3">
                    <Form.Text className="text-danger">{loginError}</Form.Text>
                </Form.Group>
            )}
        </Form>
    );
};
