import { useState } from "react";
import { PersonPlusFill } from "react-bootstrap-icons";
import { getUserTypeForLogin } from "services/loginService";
import LoginForm from "./LoginForm";
import ForgotPassword from "./ForgotPassword";
import { useLocation } from "react-router-dom";
import { UserType } from "models/User";
import LoginOptions from "./LoginOptions";
import LoginHeading from "./LoginHeading";

export default () => {
    const pathname = useLocation().pathname.trim().toLowerCase();
    const isCamplify = pathname === "/camplify";
    const [showModal, setShowModal] = useState(false);

    const userType: UserType = getUserTypeForLogin(pathname);

    return (
        <>
            <div className="sign-in">
                <div>
                    <LoginHeading />
                    <LoginForm
                        showForgotPasswordModal={() => setShowModal(true)}
                        userType={userType}
                    />
                </div>
                {!isCamplify && <LoginOptions />}
                <div className="bottom-link mt-5 text-center">
                    <PersonPlusFill />{" "}
                    <a href="/activate">Activate/Register a new device</a>
                </div>
            </div>
            {isCamplify && (
                <div className="powered-by">Powered by Black Knight</div>
            )}
            <ForgotPassword
                show={showModal}
                onHide={() => setShowModal(false)}
            />
        </>
    );
};
