import { Link, useLocation } from "react-router-dom";
import { getUserTypeForLogin } from "services/loginService";
import { UserType } from "models/User";
import { ArrowLeftCircle } from "react-bootstrap-icons";

import fleetLogo from "assets/images/motorbike.png";
import dealerLogo from "assets/images/agreement.png";
import policeLogo from "assets/images/police.png";

export default () => {
    const pathname = useLocation().pathname.trim().toLowerCase();
    const userType = getUserTypeForLogin(pathname);

    return (
        <div className="login-options">
            {userType === UserType.Single && (
                <>
                    <h5>Or login as</h5>
                    <div>
                        <Link to="/fleetlogin">
                            <span>FLEET</span>
                            <img src={fleetLogo} alt="" />
                        </Link>
                        <Link to="/dealerlogin">
                            <span>DEALER</span>
                            <img src={dealerLogo} alt="" />
                        </Link>
                        <Link to="/policelogin">
                            <span>POLICE</span>
                            <img src={policeLogo} alt="" />
                        </Link>
                    </div>
                </>
            )}
            {userType !== UserType.Single && (
                <div className="back">
                    <Link to="/login">
                        <ArrowLeftCircle />
                        <span>BACK</span>
                    </Link>
                </div>
            )}
        </div>
    );
};
