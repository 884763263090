import axios from "axios";
import Cookie from "js-cookie";
import { UserType, User } from "models/User";

const loginUrl = {
    [UserType.Single]: "/_login",
    [UserType.Fleet]: "/_fleetlogin",
    [UserType.Dealer]: "/_dealerlogin",
    [UserType.Police]: "/_policelogin",
};

export function getCookies() {
    const remember = Cookie.get("remember");
    const user = Cookie.get("user");
    const pwd = Cookie.get("pwd");

    return { remember, user, pwd };
}

export function getUserTypeForLogin(pathname: string) {
    const path = pathname.trim().toLowerCase();
    return path === "/fleetlogin"
        ? UserType.Fleet
        : path === "/dealerlogin"
        ? UserType.Dealer
        : path === "/policelogin"
        ? UserType.Police
        : UserType.Single;
}

export function isLoggedIn() {
    const token = localStorage.getItem("token");
    return !!token;
}

export async function requestPassword(email: string) {
    const formData = new FormData();
    formData.append("email", email);
    const response = await axios.post("/_requestPassword", formData);
    return response.data;
}

export async function login(
    username: string,
    password: string,
    rememberMe: boolean,
    userType: UserType
) {
    const formData = new FormData();
    formData.append("pwd", password);
    formData.append("remember", rememberMe ? "1" : "0");

    if (userType === UserType.Fleet) {
        formData.append("code", username);
    } else if (userType === UserType.Dealer) {
        formData.append("name", username);
    } else if (userType === UserType.Police) {
        formData.append("name", "VicPolice");
        formData.append("regNumber", username);
    } else {
        formData.append("name", username);
    }

    const response = await axios.post(loginUrl[userType], formData);
    console.log("service", response);
    const result =
        typeof response.data === "string" ? eval(response.data) : response.data;
    return result;
}

export function saveLoggedInUser(user: User) {
    localStorage.setItem("user", JSON.stringify(user));
}

export function loggedInUser(): User | undefined {
    try {
        const user = JSON.parse(localStorage.getItem("user") ?? "");
        if (!user || !user.id || !user.token || !user.email) {
            return undefined;
        }
        return user;
    } catch {
        return undefined;
    }
}
