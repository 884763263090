import { FormEvent, useEffect, useRef, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { requestPassword } from "services/loginService";

interface ForgotPasswordProps {
    show: boolean;
    onHide: any;
}

export default ({ show, onHide }: ForgotPasswordProps) => {
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const emailRef = useRef<any>(null);
    const [sending, setSending] = useState(false);
    const [completed, setCompleted] = useState(false);

    useEffect(() => {
        if (show && emailRef.current) {
            emailRef.current.focus();
        }
    }, [show, emailRef]);

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (sending) {
            return;
        }
        if (!email || !email.trim()) {
            setError("Enter the email");
            emailRef.current.focus();
            return;
        }
        setSending(true);
        requestPassword(email).then((response) => {
            setSending(false);
            if (response) {
                setError(response);
            } else {
                setCompleted(true);
            }
        });
    };

    const onComplete = () => {
        setEmail("");
        setCompleted(false);
        setError("");
        onHide();
    };

    return (
        <Modal show={show} centered onHide={onHide}>
            <Form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>Forgot your password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {completed ? (
                        <p className="text-success">
                            Your password has been sent to your email.
                        </p>
                    ) : (
                        <Form.Group>
                            <Form.Label>Enter your email</Form.Label>
                            <Form.Control
                                type="text"
                                ref={emailRef}
                                value={email}
                                onChange={(e) => {
                                    setEmail(e.target.value);
                                    setError("");
                                }}
                            />
                            {error && (
                                <Form.Text className="text-danger">
                                    {error}
                                </Form.Text>
                            )}
                        </Form.Group>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    {completed ? (
                        <Button variant="primary" onClick={onComplete}>
                            OK
                        </Button>
                    ) : (
                        <>
                            <Button variant="secondary" onClick={onHide}>
                                Cancel
                            </Button>
                            <Button variant="primary" type="submit">
                                {sending ? "Sending..." : "OK"}
                            </Button>
                        </>
                    )}
                </Modal.Footer>
            </Form>
        </Modal>
    );
};
