export enum UserType {
    Single,
    Fleet,
    Dealer,
    Police,
}

export interface User {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    userType: UserType;
    token: string;
    lastLoggedIn: string;
}
