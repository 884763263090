import { BrowserRouter, Routes, Route } from "react-router-dom";

import Header from "components/Header";

import Login from "components/Login";

function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <Header />
                <main>
                    <Routes>
                        <Route path="/" element={<Login />}>
                            <Route path="login" element={<Login />} />
                            <Route path="camplify" element={<Login />} />
                        </Route>
                        <Route path="*" element={<Login />} />
                    </Routes>
                </main>
            </BrowserRouter>
        </div>
    );
}

export default App;
