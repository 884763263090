import { useLocation } from "react-router-dom";
import { getUserTypeForLogin } from "services/loginService";

import fleetLogo from "assets/images/motorbike.png";
import dealerLogo from "assets/images/agreement.png";
import policeLogo from "assets/images/police.png";
import { UserType } from "models/User";

export default () => {
    const userType = getUserTypeForLogin(useLocation().pathname);

    return userType === UserType.Dealer ? (
        <h2>
            <img src={dealerLogo} alt="" /> Dealer Administration
        </h2>
    ) : userType === UserType.Fleet ? (
        <h2>
            <img src={fleetLogo} alt="" /> Fleet Monitoring
        </h2>
    ) : userType === UserType.Police ? (
        <h2>
            <img src={policeLogo} alt="" /> Police Login
        </h2>
    ) : (
        <h2>Welcome! Please sign in</h2>
    );
};
