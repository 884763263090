import { Container, Nav, Navbar } from "react-bootstrap";
import { useLocation } from "react-router-dom";

const Header = () => {
    const { pathname } = useLocation();
    const isCamplify = pathname.trim().toLowerCase() === "/camplify";

    return (
        <header className="App-header">
            <Navbar className={isCamplify ? "camplify-login" : ""} expand="lg">
                <Container>
                    <Navbar.Brand></Navbar.Brand>
                    <Navbar.Toggle></Navbar.Toggle>
                    <Navbar.Collapse>
                        {!isCamplify && (
                            <Nav className="me-auto">
                                <Nav.Link href="/tracking">TRACK</Nav.Link>
                                <Nav.Link href="https://www.blackknighttracking.com/">
                                    SHOP
                                </Nav.Link>
                                <Nav.Link href="https://www.blackknighttracking.com/help">
                                    HELP+FAQ
                                </Nav.Link>
                            </Nav>
                        )}
                        <Nav className="ms-auto">
                            <Nav.Link href="/activate">
                                REGISTER/ACTIVATE
                            </Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    );
};

export default Header;
